import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const shadeStyle = {
	opacity: 0.28
}


// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Gray Labs</title>
      <h1 style={headingStyles}>
        Gray Labs <span style={shadeStyle}><br/></span>
      </h1>
		<br/>
		<br/>
     
      <p style={paragraphStyles}>
		<br/>
		<br/>
		  📫 hello@labs.stonegray.ca
		<br/>
		<br/>
		  📦 Gray Labs<br/>
		  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PO Box 99900 LY 889 038 <br/>
		  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stn. Toronto Dom.<br />
		  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toronto, Ontario, M5K 0A2<br />
		
      </p>
	  <hr/>
	  <p>(c) 2018-2022 Gray Labs </p>
    </main>
  )
}

export default IndexPage
